<div class="admin-panel">
  <h2>Admin Panel</h2>
  <button (click)="logout()" class="logout-button">Logout</button>

  <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>

  <table class="business-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Subdomain</th>
        <th>Email</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let business of businesses">
        <td>{{ business.name }}</td>
        <td>{{ business.subdomain }}</td>
        <td>{{ business.email }}</td>
        <td>
          <button (click)="editBusiness(business)" class="action-button">Edit</button>
          <button (click)="deleteBusiness(business.subdomain)" class="action-button delete-button">Delete</button>
        </td>
      </tr>
    </tbody>
  </table>

  <button (click)="openAddBusinessForm()" class="add-button">Add Business</button>

  <!-- Add New Business Form -->
  <!-- Add New Business Form -->
  <div *ngIf="isAddBusinessFormVisible" class="form-container">
    <h3>Add New Business</h3>
    <form (ngSubmit)="addBusiness()">
      <input type="text" [(ngModel)]="newBusiness.name" name="name" placeholder="Name" required />
      <input type="text" [(ngModel)]="newBusiness.subdomain" name="subdomain" placeholder="Subdomain" required />
      <input type="email" [(ngModel)]="newBusiness.email" name="email" placeholder="Email" required />
      <input type="text" [(ngModel)]="newBusiness.googleReviewLink" name="googleReviewLink"
        placeholder="Google Review Link" />
      <input type="text" [(ngModel)]="newBusiness.yelpReviewLink" name="yelpReviewLink"
        placeholder="Yelp Review Link" />
      <textarea [(ngModel)]="newBusiness.description" name="description" placeholder="Description"></textarea>

      <label for="logoUpload">Upload Logo:</label>
      <input type="file" id="logoUpload" (change)="onFileSelected($event, 'logo')" accept="image/*" />

      <label for="backgroundUpload">Upload Background Image:</label>
      <input type="file" id="backgroundUpload" (change)="onFileSelected($event, 'backgroundImage')" accept="image/*" />


      <button type="submit" class="submit-button">Submit</button>
      <button type="button" (click)="closeAddBusinessForm()" class="cancel-button">Cancel</button>
    </form>
  </div>


  <!-- Edit Business Form -->
  <!-- Edit Business Form -->
  <!-- Edit Business Form -->
  <div *ngIf="isEditBusinessFormVisible" class="form-container">
    <h3>Edit Business</h3>
    <form (ngSubmit)="updateBusiness()">
      <input type="text" [(ngModel)]="editBusinessData.name" name="name" placeholder="Name" required />
      <input type="text" [(ngModel)]="editBusinessData.subdomain" name="subdomain" placeholder="Subdomain" required />
      <input type="email" [(ngModel)]="editBusinessData.email" name="email" placeholder="Email" required />
      <input type="text" [(ngModel)]="editBusinessData.googleReviewLink" name="googleReviewLink"
        placeholder="Google Review Link" />
      <input type="text" [(ngModel)]="editBusinessData.yelpReviewLink" name="yelpReviewLink"
        placeholder="Yelp Review Link" />
      <textarea [(ngModel)]="editBusinessData.description" name="description" placeholder="Description"></textarea>

      <!-- Current Images Section -->
      <div *ngIf="editBusinessData.name" class="current-images">
        <h4>Current Images</h4>
        <div *ngIf="editBusinessData.name">
          <img [src]="assets_url+'/business-images/' + editBusinessData.name + '/logo.png'" alt="Current Logo"
            class="business-image-preview" />
          <label>Logo</label>
        </div>
        <div *ngIf="editBusinessData.name">
          <img [src]="assets_url+'/business-images/' + editBusinessData.name + '/image.png'"
            alt="Current Background Image" class="business-image-preview" />
          <label>Background Image</label>
        </div>
      </div>

      <!-- New Images Upload Section -->
      <div class="upload-section">
        <label for="logoUpload">Upload Logo:</label>
        <input type="file" id="logoUpload" (change)="onFileSelected($event, 'logo')" accept="image/*" />

        <label for="backgroundUpload">Upload Background Image:</label>
        <input type="file" id="backgroundUpload" (change)="onFileSelected($event, 'backgroundImage')"
          accept="image/*" />


      </div>

      <button type="submit" class="submit-button">Update</button>
      <button type="button" (click)="closeEditBusinessForm()" class="cancel-button">Cancel</button>
    </form>
  </div>

</div>
