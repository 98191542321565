// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';
import axios from 'axios';
import { config } from '../config/global';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = config.API_URL + '/api/auth'; // Adjust the port if necessary

  constructor() { }

  async login(username: string, password: string) {
    try {
      const response = await axios.post(`${this.apiUrl}/login`, { username, password });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }


  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return !this.isTokenExpired(token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.removeItem('token');
  }

  private isTokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
