// src/app/services/business.service.ts
import { Injectable } from '@angular/core';
import axios from 'axios';
import { AuthService } from './auth.service';
import { config } from '../config/global';

@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private apiUrl = config.API_URL + '/api/businesses'; // Adjust the port if necessary

  constructor(private authService: AuthService) { }

  async getAllBusinesses() {
    try {
      const response = await axios.get(this.apiUrl, {
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching all businesses:', error);
      throw error;
    }
  }

  async getBusinessBySubdomain(subdomain: string) {
    try {
      const response = await axios.get(`${this.apiUrl}/subdomain/${subdomain}`, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      });
      console.log('Fetched business data:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching business by subdomain:', error);
      throw error;
    }
  }

  async addBusiness(formData: FormData) {
    try {
      const response = await axios.post(`${this.apiUrl}/create`, formData, {
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,  // Ensure correct token is fetched
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding business:', error);
      throw error;
    }
  }


  async updateBusiness(business: any) {
    try {
      const response = await axios.put(`${this.apiUrl}/subdomain/${business.subdomain}`, business, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating business:', error);
      throw error;
    }
  }

  async deleteBusinessBySubdomain(subdomain: string) {
    try {
      const response = await axios.delete(`${this.apiUrl}/${subdomain}`, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting business:', error);
      throw error;
    }
  }




  async sendReview(reviewData: { to: string; subject: string; body: string }) {
    try {
      const response = await axios.post(config.API_URL + '/api/send-review', reviewData);
      return response.data;
    } catch (error) {
      console.error('Error sending review:', error);
      throw error;
    }
  }


  async uploadImage(businessName: string, file: File, type: 'logo' | 'background') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    try {
      const response = await axios.post(`${this.apiUrl}/${businessName}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  }
  async uploadImages(subdomain: string, formData: FormData) {
    try {
      const response = await axios.post(`${this.apiUrl}/${subdomain}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  }


}
