<div class="login-container">
    <h2>Admin Login</h2>
    <form (ngSubmit)="login()">
      <div class="form-group">
        <label>Username</label>
        <input [(ngModel)]="username" name="username" required />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input [(ngModel)]="password" name="password" type="password" required />
      </div>
      <button type="submit">Login</button>
      <p class="error-message" *ngIf="errorMessage">{{ errorMessage }}</p>
    </form>
  </div>
  