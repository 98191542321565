import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from '../../services/business.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import axios from 'axios';
import { config } from '../../config/global';

@Component({
  selector: 'app-business-details',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.css'],
})
export class BusinessDetailsComponent {
  business: any = {};
  loading = true; // New loading state
  selectedRating = 0;
  showStarRating = true;
  showDescription = false;
  isFeedbackFormVisible = false;
  isExperienceTextVisible = true;
  showReviewButtons = false; // Controls visibility of Google/Yelp buttons // Loading state
  collapsed = false; // State for collapsing content
  isReviewSubmitted = false; // Controls visibility of Google/Yelp buttons

  review = {
    name: '',
    email: '',
    phone: '',
    text: '',
    agree: false,
  };

  assets_url = config.ASSETS_URL

  constructor(
    private route: ActivatedRoute,
    private businessService: BusinessService,
    private router: Router
  ) { }

  subdomain: string | null = null;

  async ngOnInit() {
    this.subdomain = this.route.snapshot.paramMap.get('subdomain');
    if (this.subdomain && this.subdomain !== 'undefined') {
      try {
        this.business = await this.businessService.getBusinessBySubdomain(this.subdomain);
        this.loading = false; // Set loading to false after data is fetched
      } catch (error: any) {
        console.error('Error fetching business:', error);
        this.loading = false; // Stop loading even in case of an error
        if (error.response && error.response.status === 404) {
          this.router.navigate(['/not-found']);
        } else {
          alert('An error occurred while fetching business data.');
        }
      }
    } else {
      console.warn('Subdomain is null or undefined:', this.subdomain);
      this.router.navigate(['/not-found']);
    }
  }

  rate(star: number): void {
    this.selectedRating = star;
    this.isExperienceTextVisible = false;
    this.showStarRating = false; // Hide stars after selection
    this.showDescription = true; // Show description after star is clicked

    if (star <= 3) {
      this.isFeedbackFormVisible = true;
    } else {
      this.showReviewButtons = true;
    }
  }
  collapseContent() {
    this.collapsed = true;
    setTimeout(() => {
      this.logoMoveUp();
    }, 400);
  }

  logoMoveUp() {
    // Update the CSS for the logo and collapsed state
    const logoElement = document.querySelector('.logo');
    if (logoElement) {
      logoElement.classList.add('collapsed-content');
    }
  }


  openExternalReview(): void {
    this.showReviewButtons = true;
    this.isFeedbackFormVisible = false;
  }

  openLink(url: string): void {
    if (url) {
      window.open(url, '_blank');
    } else {
      alert('No review link available.');
    }
  }

  async sendReview(reviewData: { to: string; subject: string; body: string }) {
    try {
      const response = await axios.post(config.API_URL + '/api/send-review', reviewData);
      return response.data;
    } catch (error) {
      console.error('Error sending review:', error);
      throw error;
    }
  }



  async submitReview() {
    console.log("Submit review button clicked");
    console.log("Review data:", this.review);

    try {
      // Format the email body to include customer details and review text
      const emailBody = `
        Customer Name: ${this.review.name}
        Phone Number: ${this.review.phone}
        Review:
        ${this.review.text}
      `;

      // Send the review email to the business email address
      const response = await this.businessService.sendReview({
        to: this.business.email,
        subject: `Review from ${this.review.name}`,
        body: emailBody
      });

      console.log("Review submission successful:", response);
      this.isReviewSubmitted = true; // Update UI on success
    } catch (error) {
      console.error("Error submitting review:", error);
    }
  }


}
