<div class="container" *ngIf="!loading">
  <!-- Left Block -->
  <div class="left-block">
    <div class="center-content expand">
      <!-- Logo -->
      <div class="logo">
        <img class="logo-img" [src]="assets_url+'/business-images/' + business.name + '/logo.png'" alt="{{ business.name }} Logo"
          width="200" height="200" />
      </div>

      <!-- Experience Text -->
      <div class="experience-text" *ngIf="isExperienceTextVisible">
        <p>We'd love to hear about your experience with {{ business.name }}!</p>
      </div>

      <!-- Appreciation Text -->
      <div *ngIf="isReviewSubmitted" class="appreciation-text">
        <p>Thank you for your feedback for {{ business.name }}! We truly appreciate your input and value your time.</p>
      </div>

      <!-- Star Rating & Feedback Form -->
      <div *ngIf="!isReviewSubmitted">
        <!-- Star Rating -->
        <div *ngIf="showStarRating" class="star-rating">
          <span class="star" *ngFor="let star of [1, 2, 3, 4, 5]; let i = index" (click)="rate(i + 1)"
            [ngClass]="{ filled: (i + 1) <= selectedRating }">
            &#9733;
          </span>
        </div>

        <!-- Description Text -->
        <p *ngIf="showDescription" class="description">
          {{ business?.description || 'Default description for customer satisfaction.' }}
        </p>

        <!-- Feedback Form for 3 Stars or Less -->
        <div *ngIf="isFeedbackFormVisible" class="feedback-form">
          <!-- Name Input -->
          <div class="input-container large-input">
            <input type="text" placeholder=" " [(ngModel)]="review.name" required #name="ngModel"
              [class.invalid]="name.invalid && name.touched" />
            <label>Name</label>
          </div>

          <!-- Email and Phone Group -->
          <div class="input-group">
            <div class="input-container half-width">
              <input type="email" placeholder=" " [(ngModel)]="review.email" required
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" #email="ngModel"
                [class.invalid]="email.invalid && email.touched" />
              <label>Email Address</label>
            </div>
            <div class="input-container half-width">
              <input type="tel" placeholder=" " [(ngModel)]="review.phone" required pattern="^((\\+1-?)|0)?[0-9]{9,10}$"
                #phone="ngModel" [class.invalid]="phone.invalid && phone.touched" />
              <label>Phone Number (9 digits)</label>
            </div>
          </div>

          <!-- Review Text Area -->
          <div class="input-container large-input">
            <textarea rows="4" placeholder=" " [(ngModel)]="review.text" required #text="ngModel"
              [class.invalid]="text.invalid && text.touched"></textarea>
            <label class="review-area">Review</label>
          </div>

          <!-- Consent Checkbox -->
          <div class="consent-container">
            <input type="checkbox" [(ngModel)]="review.agree" id="consent" required #consent="ngModel" />
            <label for="consent">I consent to the processing of personal data.</label>
          </div>

          <!-- Submit Button -->
          <button (click)="submitReview()" [disabled]="
                name.invalid || email.invalid || phone.invalid || text.invalid || !review.agree
              " class="submit-button">
            Send
          </button>
          <a class="public-review-link" (click)="openExternalReview()">Leave a public review</a>
        </div>

        <!-- Review Buttons for 4 Stars or More -->
        <div *ngIf="showReviewButtons" class="review-buttons">
          <button class="review-button google-button" (click)="openLink(business?.googleReviewLink)">
            <img src="assets/icons/google-icon.png" alt="Google Icon" />
            <div class="button-text">Leave a Review on Google</div>
          </button>
          <button class="review-button yelp-button" (click)="openLink(business?.yelpReviewLink)">
            <img src="assets/icons/yelp-icon.png" alt="Yelp Icon" />
            <div class="button-text">Leave a Review on Yelp</div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Block -->
  <div class="right-block">
    <img class="business-image" [src]="assets_url+'/business-images/' + business.name + '/image.png'"
      alt="Business Image" />
  </div>
</div>
