// src/app/components/not-found/not-found.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  template: `
    <div class="not-found-container">
      <h1>Sorry, page not found.</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  `,
  styles: [
    `
      .not-found-container {
        text-align: center;
        margin-top: 100px;
        padding: 20px;
      }

      .not-found-container h1 {
        font-size: 36px;
        color: #333;
      }

      .not-found-container p {
        font-size: 18px;
        color: #666;
      }
    `,
  ],
})
export class NotFoundComponent {}
