import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../services/business.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { config } from '../../config/global';

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css'],
})
export class AdminPanelComponent implements OnInit {
  businesses: any[] = [];
  errorMessage = '';
  isAddBusinessFormVisible = false;
  isEditBusinessFormVisible = false;
  logoFile: File | null = null;
  backgroundFile: File | null = null;

  newBusiness = {
    name: '',
    subdomain: '',
    email: '',
    googleReviewLink: '',
    yelpReviewLink: '',
    description: ''
  };

  editBusinessData = {
    id: '',
    name: '',
    subdomain: '',
    email: '',
    googleReviewLink: '',
    yelpReviewLink: '',
    description: ''
  };

  assets_url = config.ASSETS_URL


  constructor(
    private businessService: BusinessService,
    private authService: AuthService,
    private router: Router
  ) { }

  async ngOnInit() {
    try {
      this.businesses = await this.businessService.getAllBusinesses();

    } catch (error) {
      console.error('Error fetching businesses:', error);
      this.errorMessage = 'Failed to load businesses.';
    }
  }

  openAddBusinessForm() {
    this.isAddBusinessFormVisible = true;
  }

  closeAddBusinessForm() {
    this.isAddBusinessFormVisible = false;
  }

  selectedFiles: { [key: string]: File } = {};

  onFileSelected(event: any, fieldName: 'logo' | 'background' | 'backgroundImage') {
    if (event.target.files.length > 0) {
      this.selectedFiles[fieldName] = event.target.files[0];
    }
  }

  async addBusiness() {
    const formData = new FormData();
    formData.append('name', this.newBusiness.name);
    formData.append('subdomain', this.newBusiness.subdomain);
    formData.append('email', this.newBusiness.email);
    formData.append('googleReviewLink', this.newBusiness.googleReviewLink || '');
    formData.append('yelpReviewLink', this.newBusiness.yelpReviewLink || '');
    formData.append('description', this.newBusiness.description || '');

    if (this.selectedFiles['logo']) {
      formData.append('logo', this.selectedFiles['logo']);
    }
    if (this.selectedFiles['backgroundImage']) {
      formData.append('backgroundImage', this.selectedFiles['backgroundImage']);
    }

    try {
      const response = await this.businessService.addBusiness(formData);
      this.businesses.push(response);
      this.isAddBusinessFormVisible = false;
      this.newBusiness = {
        name: '',
        subdomain: '',
        email: '',
        googleReviewLink: '',
        yelpReviewLink: '',
        description: ''
      };
    } catch (error) {
      console.error('Error adding business:', error);
    }
  }



  async deleteBusiness(subdomain: string) {
    try {
      await this.businessService.deleteBusinessBySubdomain(subdomain);
      // Remove the deleted business from the list
      this.businesses = this.businesses.filter(b => b.subdomain !== subdomain);
    } catch (error) {
      console.error('Error deleting business:', error);
      this.errorMessage = 'Failed to delete business.';
    }
  }

  editBusiness(business: any) {
    this.isEditBusinessFormVisible = true;
    this.editBusinessData = { ...business };
  }

  closeEditBusinessForm() {
    this.isEditBusinessFormVisible = false;
  }

  async updateBusiness() {
    try {
      const response = await this.businessService.updateBusiness(this.editBusinessData);

      if (this.logoFile) {
        await this.businessService.uploadImage(this.editBusinessData.name, this.logoFile, 'logo');
      }
      if (this.backgroundFile) {
        await this.businessService.uploadImage(this.editBusinessData.name, this.backgroundFile, 'background');
      }

      // Update the edited business in the list
      this.businesses = this.businesses.map(b =>
        b.subdomain === response.subdomain ? response : b
      );
      this.isEditBusinessFormVisible = false;
    } catch (error) {
      console.error('Error updating business:', error);
      this.errorMessage = 'Failed to update business.';
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
