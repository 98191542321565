import { Route } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { BusinessDetailsComponent } from './components/business-details/business-details.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Route[] = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'adminpanel/login', component: LoginComponent },
    { path: 'adminpanel/admin', component: AdminPanelComponent, canActivate: [AuthGuard] },
    { path: 'not-found', component: NotFoundComponent },
    { path: ':subdomain', component: BusinessDetailsComponent },
    { path: '**', redirectTo: '/not-found' },
  ];
  
